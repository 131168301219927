import ng from 'angular';

export class AcpCopyToClipboard {
  constructor(
    private $window: ng.IWindowService,
    private acpDeviceDetectionService
  ) {
    'ngInject';
  }

  public copy(text: string, onCopied?: () => void): void {
    let textArea: HTMLTextAreaElement;
    textArea = this.createTextArea(text);
    this.selectText(textArea);
    this.copyToClipboard(textArea);

    if (onCopied) {
      onCopied();
    }
  }

  private createTextArea(text: string): HTMLTextAreaElement {
    let textArea: HTMLTextAreaElement;
    textArea = this.$window.document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);

    return textArea;
  }

  private selectText(textArea: HTMLTextAreaElement): void {
    let range;
    let selection;

    if (this.acpDeviceDetectionService.isiOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);

      selection = this.$window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  private copyToClipboard(textArea: HTMLTextAreaElement): void {
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
}

export default ng
  .module('acp.shared.services.acpCopyToClipboard', [])
  .service('acpCopyToClipboard', AcpCopyToClipboard);
